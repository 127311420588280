import { render, staticRenderFns } from "./IndustryField.vue?vue&type=template&id=a65c0040&scoped=true&"
import script from "./IndustryField.vue?vue&type=script&lang=js&"
export * from "./IndustryField.vue?vue&type=script&lang=js&"
import style0 from "./IndustryField.vue?vue&type=style&index=0&id=a65c0040&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a65c0040",
  null
  
)

export default component.exports