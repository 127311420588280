<template>
  <v-alert
    v-if="downgradeAt"
    icon="mdi-arrow-down-bold-circle-outline"
    outlined
    class="mt-8"
  >
    <span class="font-weight-bold black--text">{{ $t('settings.downgrade-info.title') }}<br></span>

    <div class="black--text">
      <p
        v-html="$t('settings.downgrade-info.warning', { productType, date: formatDate(downgradeAt,'DATE_LONG') })"
      />
      <div class="d-flex flex-column align-center w-full">
        <ul>
          <li
            v-for="(feature,index) in lostFeatures"
            :key="index"
          >
            {{ $t(feature) }}
          </li>
        </ul>
        <v-alert
          type="warning"
          class="mt-4"
        >
          <b>{{ $t('labels.caution') }}: </b> {{ $t('settings.downgrade-info.info') }}
        </v-alert>
      </div>
    </div>

    <div class="d-flex justify-end">
      <v-btn
        color="primary"
        :loading="loading"
        :disabled="loading"
        @click="cancelDowngrade"
      >
        <v-icon class="mr-2">
          mdi-account-reactivate
        </v-icon>
        {{ $t('buttons.downgrade-info.cta') }}
      </v-btn>
    </div>
  </v-alert>
</template>

<script>
import { products } from '@/lib/product'
import { ProductType, isBasicUser, isExpertUser } from '@/lib/productTypes'
import CANCEL_DOWNGRADE from './queries/CancelDowngrade.gql'
import bus, { eventNames } from '@/lib/eventBus'
import branding from '@/mixins/branding'

export default {
  mixins: [branding],
  props: {
    pendingDowngrade: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      products,
      loading: false
    }
  },
  computed: {
    isBasic () {
      return isBasicUser(this.$auth.user)
    },
    isExpert () {
      return isExpertUser(this.$auth.user)
    },
    downgradeAt () {
      return this.pendingDowngrade?.downgradeAt
    },
    productType () {
      return this.pendingDowngrade?.productType
    },
    lostFeatures () {
      const basicFeatures = Object.values(this.products[this.platform]).find(product => product.productName === ProductType.BASIC).features.slice(1)
      const expertFeatures = Object.values(this.products[this.platform]).find(product => product.productName === ProductType.EXPERT).features.slice(1)

      if (this.isExpert && this.productType === ProductType.BASIC) return expertFeatures
      else if (this.isExpert && this.productType === ProductType.LIGHT) return [...basicFeatures, ...expertFeatures]
      else return basicFeatures
    }
  },
  methods: {
    async cancelDowngrade () {
      this.loading = true
      await this.$apollo.mutate({
        mutation: CANCEL_DOWNGRADE,
        variables: {
          companyId: this.$auth.user.companyId
        }
      })
      this.$emit('refetch')
      this.loading = false
      bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.settings.downgrade-info.success') })
      this.$tracking.event('Plan', this.$tracking.trackingEvents.CLICKED, 'Cancel Downgrade')
    }
  }
}
</script>

<style>

</style>
