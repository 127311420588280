<template>
  <div class="w-full">
    <v-form
      ref="textForm"
      v-model="isFormValid"
    >
      <v-row
        v-for="field of fields"
        :key="field.name"
        class="ma-0 pa-0 mb-4"
      >
        <CheckTextField
          v-model="fieldsData[field.name]"
          v-bind="field"
          :loading="isLoading[field.name]"
          :rules="(field.isRequired ? [rules.required]: [])"
          outlined
          hide-details
          validate-on-input
          @click="onClick(field.name, fieldsData[field.name])"
          @change="update(field.name, fieldsData[field.name])"
        />
      </v-row>
    </v-form>
  </div>
</template>

<script>

import { required } from '@/lib/validation'
import CheckTextField from '@/components/forms/CheckTextField'

export default {
  components: {
    CheckTextField
  },
  props: {
    fields: {
      type: Array,
      default: () => ([])
    },
    hasDebounceTimer: {
      type: Boolean,
      default: true
    }
  },
  data () {
    const fieldFlags = this.initFieldFlags()
    const fieldValues = this.mapFieldValues()

    return {
      fieldsData: { ...fieldValues },
      debounceTimeout: null,
      spinnerTimeout: null,
      isLoading: fieldFlags,
      isFormValid: false,
      isFieldValid: fieldFlags
    }
  },

  watch: {
    fields () {
      const fieldValues = this.mapFieldValues()
      this.fieldsData = { ...fieldValues }
    }
  },
  created () {
    this.rules = { required }
  },

  unmounted () {
    clearTimeout(this.spinnerTimeout)
    clearTimeout(this.debounceTimeout)
  },

  methods: {
    onClick (label, value) {
      this.$tracking.event('Settings', this.$tracking.trackingEvents.CLICKED, label, value)
    },
    async update (name, value) {
      clearTimeout(this.debounceTimeout)
      this.debounceTimeout = setTimeout(() => {
        this.isLoading[name] = true
        this.spinnerTimeout = setTimeout(async () => {
          const isFormValid = await this.$refs.textForm.validate()
          if (isFormValid) {
            this.$emit('update', { [name]: value })
          }
          this.isLoading[name] = false
        }, 1000)
      }, 2000)
    },
    initFieldFlags () {
      return this.fields.reduce((acc, field) => {
        acc[field.name] = false
        return acc
      }, {})
    },
    mapFieldValues () {
      return this.fields.reduce((acc, field) => {
        acc[field.name] = field.value
        return acc
      }, {})
    }
  }
}
</script>
