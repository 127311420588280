<template>
  <div>
    <v-dialog
      :value="show"
      width="550"
      @input="$emit('close')"
    >
      <v-card>
        <v-card-title
          class="pa-0"
        >
          <v-toolbar
            color="warning"
            dark
            flat
          >
            <v-toolbar-title>{{ $t('settings.user.delete-user-dialog.title') }}</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-btn
                icon
                dark
                @click="$emit('close')"
              >
                <v-icon large>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>

        <v-card-text class="mt-4 text-body-1">
          <div class="pb-4">
            <p
              v-html="$t('settings.user.delete-user-dialog.body-texts.0', { firstname: user.firstname, lastname: user.lastname })"
            />
            <div v-if="leads?.total > 0">
              <p
                v-html="$t('settings.user.delete-user-dialog.body-texts.1', { total: leads?.total, firstname: user.firstname, lastname: user.lastname })"
              />
              <p>
                {{ $t('settings.user.delete-user-dialog.body-texts.2') }}:
              </p>
              <v-img
                class="my-4"
                height="300"
                contain
                src="/img/leads-filter.png"
                alt="Kontaktliste mit Filter"
              />
            </div>
            <p>
              {{ $t('settings.user.delete-user-dialog.body-texts.3') }}
            </p>
            <p>
              {{ $t('settings.user.delete-user-dialog.body-texts.4') }}
            </p>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-btn
            text
            @click="$emit('close')"
          >
            {{ $t('labels.cancel') }}
          </v-btn>
          <v-spacer />

          <v-btn
            color="primary"
            :disabled="isLoading"
            :loading="isLoading"
            text
            @click="deleteUser"
          >
            {{ $t('settings.user.delete-user-dialog.title') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import LEADS_COUNT from './queries/LeadsCount.gql'
import USERS from './queries/Users.gql'
import DELETE_USER from './queries/DeleteUser.gql'
import eventBus, { eventNames } from '@/lib/eventBus'

export default {
  props: {
    user: { type: Object, default: () => ({}) },
    show: { type: Boolean, default: false }
  },
  data () {
    return {
      isLoading: false,
      valid: false,
      id: this.user?.id,
      firstname: this.user?.firstname,
      lastname: this.user?.lastname
    }
  },
  watch: {
    user: {
      handler (user) {
        this.id = user?.id
        this.firstname = user?.firstname
        this.lastname = user?.lastname
      },
      deep: true
    }
  },
  methods: {
    async deleteUser () {
      try {
        this.isLoading = true
        await this.$apollo.mutate({
          mutation: DELETE_USER,
          variables: {
            id: this.user.id
          },
          refetchQueries: [{
            query: USERS,
            variables: { companyId: this.$auth.user.companyId }
          }]
        })
        this.$tracking.event('Settings', this.$tracking.trackingEvents.CLICKED, 'Delete User')
        eventBus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.settings.user.delete-user-dialog.success') })
        this.$emit('close')
      } catch (e) {
        eventBus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.settings.user.delete-user-dialog.error') })
      } finally {
        this.isLoading = false
      }
    }
  },
  apollo: {
    leads: {
      query: LEADS_COUNT,
      variables () {
        return {
          input: {
            companyId: this.$auth.user.companyId,
            page: 1,
            itemsPerPage: 1,
            filters: {
              assignedUser: this.user.id
            }
          }
        }
      }
    }
  }
}
</script>
