<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="unset"
  >
    <v-expand-transition>
      <v-card>
        <v-card-title
          style="height: 30px"
          class="pa-0"
        >
          <v-toolbar
            color="transparent"
            dark
            flat
          >
            <v-spacer />
            <v-toolbar-items>
              <v-btn
                v-if="showCloseButton"
                color="grey"
                icon
                @click="close"
              >
                <v-icon large>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>

        <v-card-text
          class="px-0 mt-5 d-flex"
          style="max-height:700px; overflow:hidden"
        >
          <v-stepper
            ref="stepContainer"
            v-model="step"
            flat
            class="w-full"
            style="background-color: transparent; overflow: scroll"
          >
            <v-stepper-items>
              <v-stepper-content
                step="1"
                class="pa-0"
              >
                <div class="px-4 text-center">
                  <h1 class="display-1 font-weight-black">
                    {{ $t('products.title') }}
                  </h1>
                  <p class="mt-1 mb-2 text-subtitle-2">
                    {{ $t('products.subtitle') }}
                  </p>
                </div>

                <Products
                  v-if="step === 1"
                  :style="{'width': $vuetify.breakpoint.smAndDown ? '100%': '1200px'}"
                  :country-code="company.country.toUpperCase()"
                  :is-package-change="true"
                  :hide-downgrade="hideDowngrade"
                  :current-package="currentPackage"
                  class="py-0 my-0"
                  @productSelected="handleProductSelected"
                />
              </v-stepper-content>
              <v-stepper-content
                step="2"
              >
                <UpgradeWarning
                  v-if="step === 2 && isUpgrade"
                  :style="{'width': $vuetify.breakpoint.smAndDown ? '100%': '600px'}"
                  :current-package="currentPackage"
                  :new-package="newPackage"
                  @cancel="close"
                  @submit="changePackage"
                />

                <DowngradeWarning
                  v-if="step === 2 && !isUpgrade"
                  :style="{'width': $vuetify.breakpoint.smAndDown ? '100%': '600px'}"
                  :current-package="currentPackage"
                  :new-package="newPackage"
                  @cancel="close"
                  @submit="changePackage"
                />
              </v-stepper-content>
              <v-stepper-content
                step="3"
              >
                <ChangeAnimation
                  v-if="step === 3"
                  :style="{'width': $vuetify.breakpoint.smAndDown ? '100%': '800px'}"
                  :current-package="currentPackage"
                  :new-package="newPackage"
                  :is-change-subscription-done="isChangeSubscriptionDone"
                  @finished="handleLoadingFinished"
                />
              </v-stepper-content>
              <v-stepper-content
                step="4"
                class="px-0"
              >
                <UpgradeOnboarding
                  v-if="step === 4 && isUpgrade"
                  :style="{'width': $vuetify.breakpoint.smAndDown ? '100%':'800px'}"
                  :company="company"
                  :new-package="newPackage"
                  @submit="handleNextStep"
                  @scrollToTop="scrollToTop"
                />
              </v-stepper-content>
              <v-stepper-content
                step="5"
              >
                <FinishedAnimation
                  v-if="step === 5"
                  :style="{'width': $vuetify.breakpoint.smAndDown ? '100%':'800px'}"
                  :is-upgrade="isUpgrade"
                  :new-package="newPackage"
                  @finished="handleUpgradeOnboardingFinished"
                />
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>
      </v-card>
    </v-expand-transition>
  </v-dialog>
</template>

<script>
import { isUpgrade } from '@/lib/product'
import bus, { eventNames } from '@/lib/eventBus'
import { ProductType } from '@/lib/productTypes'
import Products from '@/components/Products'
import ChangeAnimation from './ChangeAnimation.vue'
import FinishedAnimation from './FinishedAnimation.vue'
import UpgradeOnboarding from './UpgradeOnboarding.vue'
import DowngradeWarning from './DowngradeWarning.vue'
import UpgradeWarning from './UpgradeWarning.vue'
import DOWNGRADE_TO_LIGHT from './queries/DowngradeToLight.gql'
import DOWNGRADE_TO_BASIC from './queries/DowngradeToBasic.gql'
import UPGRADE_TO_BASIC from './queries/UpgradeToBasic.gql'
import UPGRADE_TO_EXPERT from './queries/UpgradeToExpert.gql'

export default {
  components: {
    Products,
    ChangeAnimation,
    UpgradeOnboarding,
    FinishedAnimation,
    DowngradeWarning,
    UpgradeWarning
  },
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    company: {
      type: Object,
      default: () => {}
    },
    hideDowngrade: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      step: 1,
      newPackage: '',
      isYearly: true,
      isUpgrade: true,
      isChangeSubscriptionDone: false
    }
  },
  computed: {
    showCloseButton () {
      return this.step === 1
    },
    currentPackage () {
      return this.company?.productType
    }
  },
  methods: {
    scrollToTop () {
      this.$nextTick(() => {
        this.$refs.stepContainer.$el.scrollTop = 0
      })
    },

    handleLoadingFinished () {
      if (this.isUpgrade) this.handleNextStep()
      else this.handleNextStep(2)
    },

    handleGoToFirstStep () {
      this.step = 1
    },

    handleNextStep (value = 1) {
      const nextStep = this.step + value
      this.step = nextStep < 1 ? 1 : nextStep
    },

    async changePackage () {
      this.handlePackageChange({ productName: this.newPackage })
      this.handleNextStep()
    },

    async handleProductSelected ({ productName, isYearly }) {
      this.newPackage = productName
      this.isUpgrade = isUpgrade(this.currentPackage, this.newPackage)
      this.isYearly = isYearly
      this.handleNextStep()
      this.$tracking.event('Plans and Pricing', this.$tracking.trackingEvents.CLICKED, productName)
    },

    async handlePackageChange ({ productName }) {
      const mutation = {
        [ProductType.LIGHT]: DOWNGRADE_TO_LIGHT,
        [ProductType.BASIC]: this.isUpgrade ? UPGRADE_TO_BASIC : DOWNGRADE_TO_BASIC,
        [ProductType.EXPERT]: UPGRADE_TO_EXPERT
      }[productName]

      try {
        await this.$apollo.mutate({
          mutation,
          variables: {
            input: {
              isYearly: this.isYearly,
              companyId: this.$auth.user.companyId
            }
          }
        })

        await this.$auth.getTokenSilently({
          ignoreCache: true
        })
        this.$tracking.event('Settings', this.$tracking.trackingEvents.CLICKED, 'Switch Package')

        this.isChangeSubscriptionDone = true
      } catch (e) {
        if (e.message.match(/Cannot change from yearly to monthly/)) {
          bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: 'Wechsel von jährlich auf monatlich nicht möglich.' })
        } else {
          bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: 'Problem beim wechseln des Pakets.' })
        }
        this.handleGoToFirstStep()
      }
    },

    handleUpgradeOnboardingFinished () {
      this.$router.go(0)
    },

    close () {
      this.step = 0
      this.isChangeSubscriptionDone = false
      this.$nextTick(() => {
        this.step = 1
      })
      this.$emit('close')
    }
  }

}
</script>
