<template>
  <step-container
    :required-data="requiredData"
    :data="data"
    :on-submit="submit"
    :has-steps="isOnboarding"
    :has-changes="hasChanges"
    :hide-secondary-actions="hideSecondaryActions"
    v-on="$listeners"
  >
    <publication
      v-if="isOnboarding"
      :data="data"
      is-onboarding
      :save-ambassador-image="saveAmbassadorImage"
      :save-company-image="saveImage"
      :variant="variant"
    />

    <v-card
      v-else
      flat
    >
      <publication
        class="mx-4"
        :data="data"
        :save-ambassador-image="saveAmbassadorImage"
        :save-company-image="saveImage"
        :variant="variant"
        :delete-company-image="deleteImage"
      />
    </v-card>
  </step-container>
</template>

<script>
import featureMixin from '@/mixins/feature'
import StepContainer from './StepContainer.vue'
import UPDATE_COMPANY_AMBASSADOR from './queries/UpdateAmbassador.gql'
import UPDATE_COMPANY_THEME from './queries/UpdateCompanyTheme.gql'
import UPDATE_ONBOARDING_COMPANY_IMAGES from './queries/UpdateOnboardingCompanyImages.gql'
import DELETE_COMPANY_IMAGE from './queries/DeleteCompanyImage.gql'
import UPDATE_SLOGAN from './queries/UpdateSlogan.gql'
import UPDATE_IS_REFERENCE_CUSTOMER from './queries/UpdateIsReferenceCustomer.gql'
import PENDING_CHANGES_FOR_REGENERATION from '@/queries/PendingChangesForRegeneration.gql'
import COMPANY from './queries/Company.gql'
import Publication from '@/components/forms/Publication'
import bus, { eventNames } from '@/lib/eventBus'

export default {
  components: { StepContainer, Publication },
  mixins: [featureMixin],
  props: {
    companyId: {
      type: String,
      required: true
    },
    slogan: {
      type: String,
      default: ''
    },
    ambassador: {
      type: Object,
      required: true
    },
    theme: {
      type: Object,
      required: true
    },
    images: {
      type: Object,
      required: true
    },
    isReferenceCustomer: {
      type: Boolean,
      default: () => null
    },
    isOnboarding: {
      type: Boolean,
      default: false
    },
    previewLink: {
      type: String,
      default: ''
    },
    variant: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    links: {
      type: Object,
      default: () => ({
        website: ''
      })
    },
    address: {
      type: Object,
      default: () => ({
        street: '',
        zip: '',
        city: ''
      })
    },
    mailProvider: {
      type: String,
      default: null
    },
    hideSecondaryActions: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      data: {
        companyId: this.companyId,
        slogan: this.slogan,
        ambassador: this.ambassador,
        theme: this.theme,
        images: this.images,
        isReferenceCustomer: this.isReferenceCustomer,
        isOnboarding: this.isOnboarding,
        previewLink: this.previewLink,
        name: this.name,
        links: this.links,
        address: this.address,
        mailProvider: this.mailProvider
      }
    }
  },
  computed: {
    refetchPendingChanges () {
      return {
        query: PENDING_CHANGES_FOR_REGENERATION,
        variables: { companyId: this.companyId }
      }
    },
    refetchCompany () {
      return {
        query: COMPANY,
        variables: { id: this.companyId }
      }
    }
  },
  methods: {
    requiredData ({
      slogan,
      ambassador: {
        firstname, lastname, role, phone, email, mobile,
        image: { url: imageUrl }
      },
      theme: { colors: { primary, secondary } },
      images: { logo: { url: logoUrl } }
    }) {
      return {
        slogan,
        firstname,
        lastname,
        role,
        phone,
        email,
        imageUrl,
        primary,
        secondary,
        mobile,
        ...(this.isLogoRequired() && {
          logoUrl
        })
      }
    },

    isLogoRequired () {
      const companyFeature = this.getFeature('company')
      return companyFeature.config.isLogoEditable
    },

    hasChanges ({
      images: imagesOld,
      slogan: sloganOld,
      ambassador: ambassadorOld,
      theme: themeOld,
      isReferenceCustomer: isReferenceCustomerOld,
      isOnboarding: isOnboardingOld
    }, {
      images,
      slogan,
      ambassador,
      theme,
      isReferenceCustomer,
      isOnboarding
    }) {
      return JSON.stringify({
        images: imagesOld,
        slogan: sloganOld,
        ambassador: ambassadorOld,
        theme: themeOld,
        isReferenceCustomer: isReferenceCustomerOld,
        isOnboarding: isOnboardingOld
      }) !== JSON.stringify({
        images,
        slogan,
        ambassador,
        theme,
        isReferenceCustomer,
        isOnboarding
      })
    },
    async submit (data) {
      await Promise.all([
        this.updateAmbassador(data.ambassador),
        this.updateImages(),
        this.updateColors(data.theme.colors),
        this.updateSlogan(data.slogan),
        ...(this.isOnboarding ? [this.updateIsReferenceCustomer(data.isReferenceCustomer)] : [])
      ])
    },
    async updateColors ({ primary, secondary }) {
      await this.$apollo.mutate({
        mutation: UPDATE_COMPANY_THEME,
        variables: {
          input: {
            companyId: this.companyId,
            colors: {
              primary,
              secondary
            }
          }
        },
        refetchQueries: [this.refetchPendingChanges]
      })
    },
    async updateSlogan (slogan) {
      if (!slogan) return
      await this.$apollo.mutate({
        mutation: UPDATE_SLOGAN,
        variables: {
          input: {
            companyId: this.companyId,
            slogan
          }
        },
        refetchQueries: [this.refetchPendingChanges]
      })
    },
    async updateIsReferenceCustomer (isReferenceCustomer) {
      await this.$apollo.mutate({
        mutation: UPDATE_IS_REFERENCE_CUSTOMER,
        variables: {
          input: {
            companyId: this.companyId,
            isReferenceCustomer: Boolean(isReferenceCustomer)
          }
        },
        refetchQueries: [this.refetchPendingChanges]
      })
    },
    saveAmbassadorImage (image, type) {
      this.$set(this.data.ambassador[type], 'file', image)
      this.$set(this.data.ambassador[type], 'url', URL.createObjectURL(image))
    },
    saveImage (image, type) {
      this.$set(this.data.images[type], 'file', image)
      this.$set(this.data.images[type], 'url', URL.createObjectURL(image))
    },
    async updateImages () {
      const images = Object.keys(this.data.images).filter(key => this.data.images[key].file).map(key => ({ type: key, image: { id: this.data.images[key].id, file: this.data.images[key].file } }))
      await this.$apollo.mutate({
        mutation: UPDATE_ONBOARDING_COMPANY_IMAGES,
        variables: {
          input: {
            companyId: this.companyId,
            images
          }
        },
        update: (store, { data: { updateOnboardingCompanyImages } }) => {
          const data = store.readQuery({ query: COMPANY, variables: { id: this.companyId } })
          data.company.images = updateOnboardingCompanyImages.images
          store.writeQuery({ query: COMPANY, data, variables: { id: this.companyId } })
        },
        refetchQueries: [this.refetchPendingChanges]
      })
      bus.$emit(eventNames.TRIGGER_ANIMATION)
    },

    async deleteImage (type) {
      if (this.data.images[type].id) {
        await this.$apollo.mutate({
          mutation: DELETE_COMPANY_IMAGE,
          variables: {
            input: {
              companyId: this.data.companyId,
              type
            }
          },
          update: (store, { data: { deleteCompanyImage } }) => {
            bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: 'Erfolgreich gelöscht' })
          },
          refetchQueries: [this.refetchCompany, this.refetchPendingChanges]
        })
        this.$tracking.event('Settings', this.$tracking.trackingEvents.CLICKED, 'Delete Image')
      }

      this.data.images[type] = {}
    },

    async updateAmbassador ({
      salutation, firstname, lastname, role, email, phone,
      mobile, signature: { text, html, customTemplateSignature }, image
    }) {
      await this.$apollo.mutate({
        mutation: UPDATE_COMPANY_AMBASSADOR,
        variables: {
          input: {
            companyId: this.companyId,
            salutation,
            firstname,
            lastname,
            role,
            email,
            phone,
            mobile,
            signature: { text, html, customTemplateSignature },
            ...(image.file && {
              image: {
                id: image.id,
                file: image.file
              }
            })
          }
        },
        update: (store, { data: { ambassador } }) => {
          const data = store.readQuery({ query: COMPANY, variables: { id: this.companyId } })
          data.company.ambassador = { ...ambassador }
          store.writeQuery({ query: COMPANY, data, variables: { id: this.companyId } })
        },
        refetchQueries: [this.refetchPendingChanges]
      })
    }
  }
}
</script>
