<template>
  <SettingsCard
    :title="$t('settings.company.language.title')"
    :subtitle="$t('settings.company.language.subtitle')"
    :is-default-open="false"
  >
    <div class="px-4 pt-4">
      <v-row>
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <div>
            <div class="d-flex align-center">
              <span class="font-weight-bold">
                {{ $t('settings.company.language.body-texts.0') }}
              </span>
            </div>
            <span class="grey--text">
              {{ $t('settings.company.language.body-texts.1') }}
            </span><br>
            <span class="grey--text">
              {{ $t('settings.company.language.body-texts.2', {readablePlatformName}) }}
            </span>
            <span style="text-decoration: underline; color: forestgreen">
              <router-link to="/settings/profile">{{ $t('settings.company.language.body-texts.3') }}</router-link>
            </span>
            <span class="grey--text">
              {{ $t('settings.company.language.body-texts.4') }}</span>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <v-select
            outlined
            :value="companyLocale"
            :items="availableLocales"
            @change="updateCompanyLocale"
          >
            <template v-slot:item="data">
              {{ $t(`language.${data.item}`) }}
            </template>
            <template v-slot:selection="data">
              {{ $t(`language.${data.item}`) }}
            </template>
          </v-select>
        </v-col>
      </v-row>
    </div>
  </SettingsCard>
</template>
<script>
import SettingsCard from '@/components/SettingsCard.vue'
import brandingMixin from '@/mixins/branding'
import featureMixin from '@/mixins/feature'
import { LanguageCodes } from '@/mixins/languages'
import UPDATE_COMPANY_LOCALE from '@/modules/settings/companySettings/queries/UpdateCompanyLocale.gql'
import { showSnackbarMessage } from '@/lib/snackbarMessages'
export default {
  components: { SettingsCard },
  mixins: [brandingMixin, featureMixin],
  props: {
    company: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      localeMapping: {
        en: 'en-GB',
        de: 'de-DE'
      }
    }
  },
  computed: {
    companyLocale () {
      if (this.company.locale) {
        return this.company.locale
      }

      const locale = this.$i18n.locale
      return this.localeMapping[locale] || locale
    },
    availableLocales () {
      if (this.company.locale) {
        return LanguageCodes
      }

      return LanguageCodes.map(locale => this.localeMapping[locale] || locale)
    }
  },
  methods: {
    async updateCompanyLocale (locale) {
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_COMPANY_LOCALE,
          variables: {
            input: {
              locale: locale
            }
          }
        })
        this.$emit('updateCompanyLocale')
        this.$tracking.event('Settings', this.$tracking.trackingEvents.EDITED, 'Update Company')
        showSnackbarMessage('success', this.$t('alerts.language-switch.success'))
      } catch (e) {
        showSnackbarMessage('error', this.$t('alerts.language-switch.error'))
      }
    }
  }
}
</script>
