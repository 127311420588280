<template>
  <SettingsCard
    :title="$t('settings.company.images.title')"
    :subtitle="$t('settings.company.images.subtitle')"
    icon="mdi-camera"
    :is-default-open="false"
  >
    <div class="pt-4 px-4">
      <v-row>
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <div>
            <div class="d-flex align-center">
              <span class="font-weight-bold">
                {{ $t('settings.company.images.body-texts.0') }}
              </span>
            </div>
            <span class="grey--text">
              {{ $t('settings.company.images.body-texts.1', { platform: readablePlatformName }) }}<br><br>
            </span>
            <span class="grey--text">
              {{ $t('settings.company.images.body-texts.2') }}
            </span>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <ImageContainer
            v-bind="portrait"
            :save-image="updateAmbassadorImage"
          />
        </v-col>
      </v-row>
      <v-row
        v-if="isLogoEditable"
        class="mt-8 mb-2"
      >
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <div>
            <div class="d-flex align-center">
              <span class="font-weight-bold">
                {{ $t('settings.company.images.body-texts.3') }}
              </span>
            </div>
            <span class="grey--text">
              {{ $t('settings.company.images.body-texts.4') }}<br><br>
            </span>
            <span class="grey--text">
              {{ $t('settings.company.images.body-texts.5') }}
            </span>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <ImageContainer
            v-bind="logo"
            :save-image="updateImage"
          />
        </v-col>
      </v-row>
      <v-row class="mt-8 mb-2">
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <div>
            <div class="d-flex align-center">
              <span class="font-weight-bold">
                {{ $t('settings.company.images.body-texts.6') }}
              </span>
            </div>
            <span class="grey--text">
              {{ $t('settings.company.images.body-texts.7', { platform: readablePlatformName }) }}<br><br>
            </span>
            <span class="grey--text">
              {{ $t('settings.company.images.body-texts.8') }}
            </span>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <ImageContainer
            v-bind="team"
            :save-image="updateImage"
          />
        </v-col>
      </v-row>
      <v-row class="mt-8 mb-2">
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <div>
            <div class="d-flex align-center">
              <span class="font-weight-bold">
                {{ $t('settings.company.images.body-texts.9') }}
              </span>
            </div>
            <span class="grey--text">
              {{ $t('settings.company.images.body-texts.10') }}<br><br>
            </span>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <ImageContainer
            v-bind="region"
            :save-image="updateImage"
          />
        </v-col>
      </v-row>
      <Feature :feature-slug="featureNames.VALUATION">
        <v-row class="mt-8 mb-2">
          <v-col
            cols="12"
            sm="6"
            class="px-0 px-md-2"
          >
            <div>
              <div class="d-flex align-center">
                <span class="font-weight-bold">
                  {{ $t('settings.company.images.body-texts.11') }}
                </span>
              </div>
              <span class="grey--text">
                {{ $t('settings.company.images.body-texts.12') }}<br> <br>
              </span>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <ImageContainer
              v-bind="lpValuationHero"
              :save-image="updateImage"
            />
          </v-col>
        </v-row>
      </Feature>
    </div>
  </SettingsCard>
</template>

<script>
import SettingsCard from '@/components/SettingsCard.vue'
import bus, { eventNames } from '@/lib/eventBus'
import UPDATE_COMPANY_IMAGES from './queries/UpdateCompanyImages.gql'
import UPDATE_COMPANY_AMBASSADOR_PORTRAIT from './queries/UpdateCompanyAmbassadorPortrait.gql'
import PENDING_CHANGES_FOR_REGENERATION from '@/queries/PendingChangesForRegeneration.gql'

import COMPANY from './queries/CompanyData.gql'
import ImageContainer from '@/components/ImageContainer.vue'
import featureMixin from '@/mixins/feature'
import branding from '@/mixins/branding'

export default {
  components: {
    SettingsCard,
    ImageContainer
  },
  mixins: [featureMixin, branding],
  props: {
    company: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    isLogoEditable () {
      return this.getFeature(this.featureNames.COMPANY)?.config?.isLogoEditable
    },
    logo () {
      return {
        name: 'logo',
        title: this.$t('settings.company.images.labels.company-logo'),
        src: this.company.images.logo.url,
        minHeight: 100,
        minWidth: 1200
      }
    },
    portrait () {
      return {
        name: 'image',
        title: this.$t('settings.company.images.labels.portrait-picture'),
        src: this.company.ambassador.image?.url,
        minHeight: 1200,
        minWidth: 1200,
        ratio: 1
      }
    },
    team () {
      return {
        name: 'team',
        title: this.$t('settings.company.images.labels.team-picture'),
        src: this.company.images.team?.url,
        minHeight: 800,
        minWidth: 1200,
        ratio: 1.5
      }
    },
    region () {
      return {
        name: 'region',
        title: this.$t('settings.company.images.labels.region-picture'),
        src: this.company.images.region?.url,
        minHeight: 800,
        minWidth: 1200,
        ratio: 1.5,
        askForImageRights: true,
        canDeleteImage: true
      }
    },
    lpValuationHero () {
      return {
        name: 'lpValuationHero',
        title: this.$t('settings.company.images.labels.valuation-picture'),
        src: this.company.images.lpValuationHero?.url,
        minHeight: 650,
        minWidth: 1950,
        ratio: 3,
        disabled: !this.getFeature(this.featureNames.VALUATION).config.hasLpValuationHero,
        askForImageRights: true,
        canDeleteImage: true
      }
    }
  },
  methods: {
    async updateImage (file, type = 'logo') {
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_COMPANY_IMAGES,
          variables: {
            input: {
              companyId: this.company.id,
              images: [
                { type, image: { id: this.company.images[type]?.id, file } }
              ]
            }
          },
          refetchQueries: [
            { query: COMPANY, variables: { id: this.company.id } },
            { query: PENDING_CHANGES_FOR_REGENERATION, variables: { companyId: this.company.id } }
          ]
        })
        this.$tracking.event('Settings', this.$tracking.trackingEvents.UPLOAD, 'Uploaded')
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.settings.company.success') })
      } catch (e) {
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.settings.company.error') })
      }
    },
    async updateAmbassadorImage (file) {
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_COMPANY_AMBASSADOR_PORTRAIT,
          variables: {
            input: {
              companyId: this.company.id,
              image: {
                id: this.company.ambassador.image?.id,
                file
              }
            }
          },
          refetchQueries: [
            { query: COMPANY, variables: { id: this.company.id } },
            { query: PENDING_CHANGES_FOR_REGENERATION, variables: { companyId: this.company.id } }
          ]
        })

        this.$tracking.event('Settings',
          this.$tracking.trackingEvents.UPLOAD,
          'Uploaded')
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.settings.company.success') })
      } catch (e) {
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.settings.company.error') })
      }
    }
  }
}
</script>
