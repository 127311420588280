<template>
  <SettingsCard
    :title="$t('settings.company.colors.title')"
    :subtitle="$t('settings.company.colors.subtitle')"
    icon="mdi-palette"
    :is-default-open="false"
  >
    <div class="py-4 px-4">
      <v-row>
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <div>
            <div class="d-flex align-center">
              <span class="font-weight-bold">
                {{ $t('settings.company.colors.body-texts.0') }}
              </span>
            </div>
            <span class="grey--text">
              {{ $t('settings.company.colors.body-texts.1') }}
            </span>
          </div>
        </v-col>
        <v-col
          v-if="primaryColor"
          cols="12"
          sm="6"
          class="d-flex flex-column justify-center px-0 px-md-2 mt-4"
        >
          <div
            class="d-flex justify-center align-end"
            @click="triggerPrimaryDialog()"
          >
            <ContrastIndicator
              :selected-color="primaryColor"
              :has-sufficient-contrast="hasPrimarySufficientContrast"
            />
            <SetColorDialog
              v-model="primaryColor"
              :title="$t('settings.company.colors.adjust-primary-color')"
              :abort="resetPrimaryColor"
              :save="updateColors"
              :show-dialog="showPrimaryDialog"
              :has-sufficient-contrast="hasPrimarySufficientContrast"
            />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <div>
            <div class="d-flex align-center">
              <span class="font-weight-bold">
                {{ $t('settings.company.colors.body-texts.2') }}
              </span>
            </div>
            <span class="grey--text">
              {{ $t('settings.company.colors.body-texts.3') }}
            </span>
          </div>
        </v-col>
        <v-col
          v-if="secondaryColor"
          cols="12"
          sm="6"
          class="d-flex flex-column justify-center px-0 px-md-2 mt-4"
        >
          <div
            class="d-flex justify-center align-end"
            @click="triggerSecondaryDialog()"
          >
            <ContrastIndicator
              :selected-color="secondaryColor"
              :has-sufficient-contrast="hasSecondarySufficientContrast"
            />
            <SetColorDialog
              v-model="secondaryColor"
              :title="$t('settings.company.colors.adjust-secondary-color')"
              :abort="resetSecondaryColor"
              :save="updateColors"
              :show-dialog="showSecondaryDialog"
              :has-sufficient-contrast="hasSecondarySufficientContrast"
            />
          </div>
        </v-col>
      </v-row>
    </div>
  </SettingsCard>
</template>

<script>
import bus, { eventNames } from '@/lib/eventBus'
import SettingsCard from '@/components/SettingsCard.vue'
import ContrastIndicator from '../../../components/ContrastIndicator.vue'
import SetColorDialog from '../../../components/SetColorDialog.vue'
import UPDATE_COMPANY_THEME from './queries/UpdateCompanyTheme.gql'
import COMPANY from './queries/CompanyData.gql'
import PENDING_CHANGES_FOR_REGENERATION from '@/queries/PendingChangesForRegeneration.gql'

export default {
  components: {
    SettingsCard,
    ContrastIndicator,
    SetColorDialog
  },
  props: {
    company: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      primaryColor: null,
      secondaryColor: null,
      showPrimaryDialog: false,
      showSecondaryDialog: false,
      maxBrightness: 186
    }
  },
  computed: {
    primaryBrightness () {
      const { red, green, blue } = this.hexToRgb(this.primaryColor)
      return red * 0.299 + green * 0.587 + blue * 0.114
    },
    hasPrimarySufficientContrast () {
      return this.primaryBrightness <= this.maxBrightness
    },
    secondaryBrightness () {
      const { red, green, blue } = this.hexToRgb(this.secondaryColor)
      return red * 0.299 + green * 0.587 + blue * 0.114
    },
    hasSecondarySufficientContrast () {
      return this.secondaryBrightness <= this.maxBrightness
    }
  },
  mounted () {
    this.primaryColor = this.company.theme.colors.primary
    this.secondaryColor = this.company.theme.colors.secondary
  },
  methods: {
    triggerPrimaryDialog () {
      this.$tracking.event('Settings', this.$tracking.trackingEvents.CLICKED, 'Adjust Primary Color')
      this.showPrimaryDialog = true
    },
    triggerSecondaryDialog () {
      this.$tracking.event('Settings', this.$tracking.trackingEvents.CLICKED, 'Adjust Secondary Color')
      this.showSecondaryDialog = true
    },
    resetPrimaryColor () {
      this.$tracking.event('Settings', this.$tracking.trackingEvents.CLICKED, 'Reset Primary Color')
      this.showPrimaryDialog = false
      this.primaryColor = this.company.theme.colors.primary
    },
    resetSecondaryColor () {
      this.$tracking.event('Settings', this.$tracking.trackingEvents.CLICKED, 'Reset Secondary Color')
      this.showSecondaryDialog = false
      this.secondaryColor = this.company.theme.colors.secondary
    },
    hexToRgb (hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
      return result ? {
        red: parseInt(result[1], 16),
        green: parseInt(result[2], 16),
        blue: parseInt(result[3], 16)
      } : {
        red: 0,
        green: 0,
        blue: 0
      }
    },
    async updateColors () {
      this.showPrimaryDialog = false
      this.showSecondaryDialog = false

      try {
        await this.$apollo.mutate({
          mutation: UPDATE_COMPANY_THEME,
          variables: {
            input: {
              companyId: this.company.id,
              colors: {
                primary: this.primaryColor,
                secondary: this.secondaryColor
              }
            }
          },
          refetchQueries: [
            { query: COMPANY, variables: { id: this.company.id } },
            { query: PENDING_CHANGES_FOR_REGENERATION, variables: { companyId: this.company.id } }
          ]
        })
        this.$tracking.event('Settings', this.$tracking.trackingEvents.EDITED, 'Update Company Theme')
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.settings.company.success') })
      } catch (e) {
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.settings.company.error') })
      }
    }
  }
}
</script>
